<template>
	<modal class="NoxModalAdminVPN" name="NoxModalAdminVPN" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'editVPNFile'">Редактирование файла</span>
					<span v-else-if="noxType == 'addVPNServer'">Добавление сервера</span>
					<span v-else-if="noxType == 'editVPNServer'">Редактирование сервера</span>
					<span v-else-if="noxType == 'deleteVPNServer'">Удаление сервера</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="noxType == 'editVPNFile'">
						<div class="nox_modal_info_row last">
							<textarea class="nox_textarea" v-model="noxVPNFile" placeholder="укажите содержимое файла"></textarea>
							<div v-html="noxAlertVPNFile"></div>
						</div>
					</div>
					<div v-else-if="['addVPNServer', 'editVPNServer'].includes(noxType)">
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Адрес сервера:</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxVPNAddress" placeholder="укажите адрес сервера (пример: 192.168.0.1:80)" autocomplete="off">
								<div v-html="noxAlertVPNAddress"></div>
							</div>
						</div>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label">Страна сервера:</div>
							<div class="nox_modal_info_row_value">
								<v-select class="nox_select style-chooser" v-model.trim="noxVPNCountry" placeholder="выберите страну сервера" :options="$parent.$parent.$parent.getSelectOptions(1)"></v-select>
								<div v-html="noxAlertVPNCountry"></div>
							</div>
						</div>
					</div>
					<div v-else-if="noxType == 'deleteVPNServer'">Вы действительно хотите удалить сервер?</div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-if="noxType == 'addVPNServer'" v-html="$store.getters.getLanguageText('1.1', 5)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="['editVPNFile', 'editVPNServer'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
					<button type="button" class="nox_button common red" @click="axios" v-else-if="noxType == 'deleteVPNServer'" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxData: {},
			noxAlert: '',
			noxAlertVPNFile: '',
			noxAlertVPNAddress: '',
			noxAlertVPNCountry: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxVPNFile: '',
			noxVPNAddressAddress: '',
			noxVPNAddressPort: '',
			noxVPNAddress: '',
			noxVPNCountry: '',
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.noxData = {};
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxVPNFile = '';
				this.noxVPNAddressAddress = '';
				this.noxVPNAddressPort = '';
				this.noxVPNAddress = '';
				this.noxVPNCountry = '';
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'editVPNFile') {
					this.noxVPNFile = this.$parent.noxVPNFile.file;
				}
				else if (this.noxType == 'editVPNServer') {

					this.noxData = this.$parent.noxVPNServers[this.noxIndex];
					this.noxVPNAddress = this.noxData.address + ':' + this.noxData.port;

					for (var i in this.$parent.$parent.$parent.getSelectOptions(1)) {
						if (this.$parent.$parent.$parent.getSelectOptions(1)[i].code == this.noxData.country) {
							this.noxVPNCountry = this.$parent.$parent.$parent.getSelectOptions(1)[i]; break;
						}
					}
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertVPNFile = '';
				this.noxAlertVPNAddress = '';
				this.noxAlertVPNCountry = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать содержимое файла.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верное содержимое файла.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать id сервера.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верный id сервера.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать адрес сервера.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верный адрес сервера.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо выбрать страну сервера.'; }
				else if (i == 8) { this.noxTemp = 'Необходимо выбрать верную страну сервера.'; }
				else if (i == 9) { this.noxTemp = 'Id сервера не найден в системе.'; }
				else if (i == 10) { this.noxTemp = 'Такой адрес сервера уже есть в системе.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (_this.noxType == 'editVPNFile') {
						if (!_this.noxVPNFile) {
							_this.noxAlertVPNFile = _this.getError(1);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/vpn/file';
							config.data = { file: _this.noxVPNFile };
							config.method = 'patch';
						}
					}
					else if (['addVPNServer', 'editVPNServer'].includes(_this.noxType)) {
						if (!_this.noxVPNAddress) {
							_this.noxAlertVPNAddress = _this.getError(5);
						}
						else {
							var noxVPNAddressData = String(_this.noxVPNAddress).split(':', 2);
							_this.noxVPNAddressAddress = (noxVPNAddressData[0] !== undefined ? noxVPNAddressData[0] : '');
							_this.noxVPNAddressPort = (noxVPNAddressData[1] !== undefined ? noxVPNAddressData[1] : 0);
							if (!_this.$store.state.noxRegexServerName.test(_this.noxVPNAddressAddress) && !_this.$store.state.noxRegexServerAddr.test(_this.noxVPNAddressAddress)) {
								_this.noxAlertVPNAddress = _this.getError(6);
							}
							else if (!_this.$store.state.noxRegexServerPort.test(_this.noxVPNAddressPort)) {
								_this.noxAlertVPNAddress = _this.getError(6);
							}
						}
						if (!_this.noxVPNCountry) {
							_this.noxAlertVPNCountry = _this.getError(7);
						}
						else if (_this.noxVPNCountry.code == undefined) {
							_this.noxAlertVPNCountry = _this.getError(8);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/vpn/servers';
							config.data = { id: _this.noxId, address: _this.noxVPNAddressAddress, port: _this.noxVPNAddressPort, country: _this.noxVPNCountry.code };
							config.method = _this.noxType == 'addVPNServer' ? 'post' : 'patch';
						}
					}
					else if (_this.noxType == 'deleteVPNServer') {
						config.url = '/v2/admin/account/vpn/servers';
						config.data = { id: _this.noxId };
						config.method = 'delete';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'editVPNFile') {
									_this.$set(_this.$parent.noxVPNFile, 'file', _this.noxVPNFile);
								}
								else if (_this.noxType == 'addVPNServer') {
									_this.$parent.noxVPNServers.push(data.data);
								}
								else if (_this.noxType == 'editVPNServer') {
									_this.$parent.noxVPNServers.splice(_this.noxIndex, 1, data.data);
								}
								else if (_this.noxType == 'deleteVPNServer') {
									_this.$parent.noxVPNServers.splice(_this.noxIndex, 1);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (_this.noxType == 'editVPNFile') {
										if      (data.response.data.error == 'FILE_IS_EMPTY') { _this.noxAlertVPNFile = _this.getError(1); }
										else if (data.response.data.error == 'FILE_NOT_VALID') { _this.noxAlertVPNFile = _this.getError(2); }
									}
									else if (['addVPNServer', 'editVPNServer', 'deleteVPNServer'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(3); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(4); }
										else if (data.response.data.error == 'ADDRESS_IS_EMPTY') { _this.noxAlertVPNAddress = _this.getError(6); }
										else if (data.response.data.error == 'ADDRESS_NOT_VALID') { _this.noxAlertVPNAddress = _this.getError(6); }
										else if (data.response.data.error == 'PORT_IS_EMPTY') { _this.noxAlertVPNAddress = _this.getError(6); }
										else if (data.response.data.error == 'PORT_NOT_VALID') { _this.noxAlertVPNAddress = _this.getError(6); }
										else if (data.response.data.error == 'COUNTRY_IS_EMPTY') { _this.noxAlertVPNCountry = _this.getError(7); }
										else if (data.response.data.error == 'COUNTRY_NOT_VALID') { _this.noxAlertVPNCountry = _this.getError(8); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (['addVPNServer', 'editVPNServer', 'deleteVPNServer'].includes(_this.noxType)) {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(9); }
									}
								}
								else if (data.response.status == 409) {
									if (['addVPNServer', 'editVPNServer', 'deleteVPNServer'].includes(_this.noxType)) {
										if (data.response.data.error == 'ADDRESS_DUPLICATE') { _this.noxAlertVPNAddress = _this.getError(10); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalAdminVPN');
			}
		}
	}
</script>
